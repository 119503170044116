<template>
  <v-layout justify-center align-center>
    <v-flex md8 lg6>
      <form @submit.prevent="submit()">
        <v-card>
          <v-card-title>
            <div class="headline">
              {{ $lang("AUTH:UPDATE_EMAIL:CARD_TITLE-TEXT") }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              :label="$lang('AUTH:UPDATE_EMAIL:INPUT_TEXT_EMAIL-LABEL')"
              :hint="$lang('AUTH:UPDATE_EMAIL:INPUT_TEXT_EMAIL-HINT')"
              v-model="user.email"
              type="email"
              :error-messages="errors && errors.email ? errors.email : []"
            ></v-text-field>

            <v-text-field
              :label="$lang('AUTH:UPDATE_EMAIL:INPUT_TEXT_PASSWORD-LABEL')"
              :hint="$lang('AUTH:UPDATE_EMAIL:INPUT_TEXT_PASSWORD-HINT')"
              v-model="user.password"
              type="password"
              :error-messages="errors && errors.password ? errors.password : []"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <div class="text-xs-center">
              <v-btn outlined type="submit">{{
                $lang("AUTH:UPDATE_EMAIL:BUTTON_SUBMIT-TEXT")
              }}</v-btn>
              <v-btn text @click="$router.push({ name: 'home' })">{{
                $lang("AUTH:UPDATE_EMAIL:BUTTON_SECONDARY-TEXT")
              }}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "update-email",
  data() {
    return {
      user: { email: null, password: null },
    };
  },
  computed: {
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    ...mapActions({
      updateEmail: "auth/updateEmail",
    }),
    submit() {
      this.updateEmail({ user: this.user, context: this })
        .then((res) => {
          this.$router.replace({ name: "home" });
        })
        .catch((err) => {});
    },
  },
};
</script>


